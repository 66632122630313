import route                                                                                     from "ziggy-js";
import {
    showAlert
} from "../../../../../resources/js/utils";
import {BRModal} from "@dbetts/brjstools";

function setCommSearchDate(e)
{
    let input = this.querySelector('input');
    let label = this.querySelector('label');

    let date = new Date(e.date);
    let formattedDate = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();

    input.value = formattedDate;
    input.setAttribute('value', formattedDate);

    label.classList.add('has-value');
}

























function sendContactMessage()
{
    let reason = document.querySelector('[id="comm_reason"]');
    let name = document.getElementById('contact_name');
    let email = document.getElementById('contact_email');
    let message = document.getElementById('message');
    let proceed = true;

    let reasonError = reason.parentElement.nextElementSibling;
    if ( reason.querySelector('input[type="radio"]:checked').value === '' ) {
        reasonError.innerHTML = "Please choose a reason for the message";
        reasonError.classList.remove('hidden');
        proceed = false;
    }
    else {
        reasonError.classList.add('hidden');
    }

    let nameError = name.parentElement.nextElementSibling;
    if ( name.value === '' ) {
        nameError.innerHTML = "Please input your name";
        nameError.classList.remove('hidden');
        proceed = false;
    }
    else {
        nameError.classList.add('hidden');
    }

    let emailError = email.parentElement.nextElementSibling;
    if ( email.value === '' ) {
        emailError.innerHTML = "Please input your email address";
        emailError.classList.remove('hidden');
        proceed = false;
    }
    else {
        emailError.classList.add('hidden');
    }

    let messError = message.parentElement.nextElementSibling;
    if ( message.value === '' ) {
        messError.innerHTML = "Please share a message with us";
        messError.classList.remove('hidden');
        proceed = false;
    }
    else {
        messError.classList.add('hidden');
    }

    if ( !proceed ) {
        return false;
    }

    // Post the message
    axios.post(route('contact.message'),
               {
                   reason: reason.querySelector('input[type="radio"]:checked').value,
                   name: name.value,
                   email: email.value,
                   message: message.value,
               })
        .then(function(res) {
            let data = res.data;
            if ( data.Status === 'Success' ) {

                let modalEl = document.getElementById('defaultModal');
                // Create and show the modal.
                let brModal = new BRModal(modalEl);
                brModal.setTitle(data.Result.title);
                brModal.setBody(data.Result.message);
                brModal.hideFooter();
                brModal.show();

                // Clear the Contact fields.
                name.value = '';
                name.nextElementSibling.classList.remove('has-value');
                email.value = '';
                email.nextElementSibling.classList.remove('has-value');
                message.value = '';
                message.nextElementSibling.classList.remove('has-value');
                reason.previousElementSibling.children[0].innerHTML = '&nbsp';
                reason.parentElement.nextElementSibling.classList.remove('has-value');
            }
            else {
                showAlert('We encountered a system error:<br>' + data.Message, 'System Error');
            }
        })
        .catch(function(error) {

            if ( error.response !== undefined ) {
                if ( error.response.status === 422 ) {
                    // Clean old errors first.


                    let errors = error.response.data.errors;
                    for ( let field in errors ) {
                        if ( errors.hasOwnProperty(field) ) {
                            switch ( field ) {
                                case 'name':
                                    nameError.innerHTML = "Please input your name";
                                    nameError.classList.remove('hidden');
                                    break;
                                case 'reason':
                                    reasonError.innerHTML = "Please choose a reason for the message";
                                    reasonError.classList.remove('hidden');
                                    break;
                                case 'email':
                                    emailError.innerHTML = "Please input your email address";
                                    emailError.classList.remove('hidden');
                                    break;
                                case 'message':
                                    messError.innerHTML = "Please share a message with us";
                                    messError.classList.remove('hidden');
                                    break;
                            }
                        }
                    }
                }
                else {
                    console.log(error);
                }
            }
            else {
                console.log(error);
            }
        });
}





//#################################
//#####   Page Support   ##########
//#################################


export {
    setCommSearchDate,
    sendContactMessage,
}