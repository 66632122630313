import { animateInput, animateSelect } from "./modules/utilities";
import { addListener }                                     from "../../../../resources/js/utils";
import { sendContactMessage } from "./modules/account-support";
import GlobalSearch                                        from "./class/widgets/GlobalSearch";
import AuthHeader                                          from "./class/widgets/AuthHeader";
import Footer                                              from "./class/widgets/Footer";
import {BRDropdown} from "@dbetts/brjstools";

window.addEventListener('load', function() {

    new GlobalSearch();
    new AuthHeader();
    new Footer();
    BRDropdown.initAll();

    if (document.querySelector('.sendContactMessage') !== null) {
        addListener('click', '.sendContactMessage', sendContactMessage);

        animateInput('contact_name', document);
        animateInput('contact_email', document);
        animateInput('message', document, true);
        animateSelect('comm_reason', document);
    }
});